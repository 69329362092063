
  import { Component, Vue } from 'vue-property-decorator'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('app', ['toggleDrawer']),
  },
})
  export default class DrawerToggle extends Vue {
  toggleDrawer!: (payload?: boolean) => void
  }
